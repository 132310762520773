<template>
    <div class="bg">
        <v-container class="pt-4 mt-0">
            <v-row>
                <v-col cols="12">
                    <h1 style="padding-top:0; text-align: center;"><strong>Minhas</strong> metas</h1>
                    <v-row>
                        <v-col v-for="(m, i) in metas.lista" :key="i" cols="12" md="6" lg="4" xl="3">
                            <v-card>
                                <v-card-title>{{m.turma}}</v-card-title>
                                <v-divider/>
                                <v-card-text style="white-space: pre-line;" v-html="m.meta"></v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
export default {
    name: "Metas",
    components: {},
    data: () => ({
        erro : {
            visivel : false,
            texto : ""
        },
        metas : {lista : []},
        carregando : false
    }),
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        metaListar() {
            axios.post(`${this.baseUrl}aluno/meta_listar`).then((res) => {
                this.metas = res.data
            })
        }
    },
    activated() {
        this.metaListar()
    }
}
</script>

<style scoped>
    h1, h2 {color: #FFFFFF;}
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px;}
    h2 {font-size: 20px;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>